import React, { useState, useLayoutEffect, useRef } from "react"
import { useSpring, a } from "react-spring"
import Slider from "rc-slider"
import { useFetchStoreVars, useFetchStore } from "Fetch"
import { types, issueAreas, locations } from "./query"
import useBreakpoint from "useBreakpoint"

import "rc-slider/assets/index.css"
import "./FilterNav.scss"

const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)

export default ({ element, filters, setFilters }) => {
  let mobile = useBreakpoint() === "mobile"
  const navref = useRef(null)
  const wrapperRef = useRef(null)
  const [navOpen, setNavOpen] = useState(false)

  useLayoutEffect(() => {
    setNavOpen(!mobile)
  }, [mobile])

  const filterTypes = useFetchStoreVars(types, { type: element }, `${element}s`)
  const filterIssuesAreas = useFetchStore(issueAreas, "issue-areas")
  const locationFilters = useFetchStore(locations, "locations")

  const updateFilters = (val, type) => {
    let currentFilters = filters[type]
    if (!val.checked) {
      currentFilters = filters[type].filter(fil => {
        return fil.indexOf(val.value) === -1
      })
    } else {
      currentFilters = currentFilters.concat(val.value)
    }

    updateUrl(currentFilters, type)
    setFilters((state) => ({ ...state, [type]: currentFilters }))
  }

  const updateUrl = (val, type) => {
    let filterType = "&filters="
    let issueType = "filter-issue="

    if (type === "issueArea") {
      issueType += val.join(",")
      filterType += filters["type"]
    } else {
      filterType += val.join(",")
      issueType += filters["issueArea"]
    }

    if (typeof window !== "undefined") {
      window.history.replaceState(null, null, `?${[filterType, issueType].join("&")}`)
    }
  }

  const style = useSpring({
    height: navOpen ? navref.current.offsetHeight : 0,
    paddingBottom: navOpen && mobile ? 50 : 0
  })

  return <nav className="filter-nav" ref={wrapperRef}>
    <div className="max-width" style={{ position: "relative" }}>
      <p className="filters-label" onClick={() => {
        setNavOpen(!navOpen)
      }}>Filters</p>
      <button className="white filter-nav-collapse icon-up"
        style={{
          transform: navOpen ? "rotate(-135deg)" : "rotate(45deg)"
        }}
        aria-label="toggle accordion"
        onClick={() => {
          setNavOpen(!navOpen)
        }}
      />
    </div>
    <a.div
      className="filter-nav-wrap max-width"
      style={style}>
      <form
        ref={navref}
        id="filter-nav"
        className="filters grid"
        style={{
          opacity: navOpen ? 1 : 0
        }}
        onSubmit={(e) => { e.preventDefault() }}>
        <div className="grid-block">
          <p className="filter-label">Filter by type:</p>
          {filterTypes && <ul className="grid grid--50" style={{
            gridGap: '0.5rem'
          }}>
            {filterTypes.categories.map(filter =>
              <li key={filter.id}>
                <label className={`custom-checkbox `} htmlFor={`filter-type-${filter.id}`}>
                  {filter.title}
                  <input
                    id={`filter-type-${filter.id}`}
                    type="checkbox"
                    name={"filters"}
                    value={filter.id}
                    aria-label="filter search"
                    checked={filters.type?.indexOf(filter.id) < 0 ? false : true}
                    onChange={(e) => {
                      updateFilters(e.currentTarget, 'type')
                    }}
                  />
                  <span className="checkbox" />
                </label>
              </li>
            )}
            {element === "newsType" ?
              <li>
                <label className="custom-checkbox" htmlFor={`filter-type-press`}>
                  LAC In the Press
                  <input
                    id={`filter-type-press`}
                    type="checkbox"
                    name={"filters"}
                    value={"press"}
                    aria-label="filter search"
                    checked={filters.section === "press"}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        setFilters({ ...filters, section: "press" })
                      } else {
                        setFilters({ ...filters, section: "news" })
                      }
                    }}
                  />
                  <span className="checkbox" />
                </label>
              </li>
              : null}
          </ul>}

          <DateRange setFilters={setFilters} filters={filters} />

          <p className="filter-label">Filter by location:</p>
          {locationFilters && <ul className="grid grid--33" style={{
            gridGap: '0.5rem',
            gridTemplateColumns: '1fr 1fr 1fr 1fr'
          }}>
            {locationFilters.categories.map(filter =>
              <li key={filter.id}>
                <label className={`custom-checkbox`} htmlFor={`filter-type-${filter.id}`}>
                  {filter.title}
                  <input
                    id={`filter-type-${filter.id}`}
                    type="checkbox"
                    name={"filters"}
                    value={filter.id}
                    aria-label="filter search"
                    checked={filters.issueArea?.indexOf(filter.id) < 0 ? false : true}
                    onChange={(e) => {
                      updateFilters(e.currentTarget, 'issueArea')
                    }}
                  />
                  <span className="checkbox" />
                </label>
              </li>
            )}
          </ul>}
        </div>

        <div className="grid-block">
          <p className="filter-label">Filter by issue area:</p>
          {filterIssuesAreas && <ul className="grid grid--33" style={{
            gridGap: '0.5rem'
          }}>
            {filterIssuesAreas.entries.map(filter =>
              <li key={filter.id}>
                <label className={`custom-checkbox `} htmlFor={`filter-issue-${filter.id}`}>
                  {filter.title}
                  <input
                    id={`filter-issue-${filter.id}`}
                    type="checkbox"
                    name={"filter-issue"}
                    value={filter.id}
                    checked={filters.issueArea?.indexOf(filter.id) < 0 ? false : true}
                    onChange={(e) => {
                      updateFilters(e.currentTarget, 'issueArea')
                    }} />
                  <span className="checkbox" />
                </label>
              </li>
            )}
          </ul>}
        </div>
        {mobile ? <div className="filter-apply">
          <button className="button defaultRed" aria-label="toggle accordion" onClick={() => { setNavOpen(false) }}>
            Apply
        </button>
        </div> : null}
      </form>
    </a.div>
  </nav>
}

const DateRange = ({ setFilters, filters }) => {
  let maxYear = new Date().getFullYear()
  let defaultDate = [2000, maxYear]

  const onSliderChange = value => {
    let reset = value[0] === defaultDate[0] && value[1] === defaultDate[1]
    setFilters((filt) => ({ ...filt, date: reset ? null : value }))
    return value
  }

  return <div className="filter-date">
    <p className="filter-label">Filter by publish date:</p>
    <div id="date-rang" className="" style={{ position: "relative" }}>
      <Range
        ariaLabelForHandle={"filter by year"}
        min={2000}
        max={maxYear}
        allowCross={false}
        value={filters.date ? filters.date : defaultDate}
        marks={{
          "2000": 2000,
          [maxYear]: "Present"
        }}
        onChange={onSliderChange} />
    </div>
  </div>
}
