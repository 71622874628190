import React from "react"
import Button from "blocks/Button"
import ArrowLink from "blocks/ArrowLink"
import Image from "blocks/Image"
import Frame from "blocks/Frame"
import BodyText from "blocks/BodyText"
import Timeline from "blocks/Timeline"
import SectionHeader from "blocks/SectionHeader"
import RelatedContent from "../RelatedContent"
import NewsModule from "blocks/NewsModule"
import { useFetchStore } from "Fetch"

import { staffIds } from "blocks/People/query"

import "./Module.scss"

export default ({ module }) => {
  return module.typeHandle === "formModule" ?
    <FormModule module={module} /> : <ModuleBuilder module={module.moduleBuilder[0]} />
}

const FormModule = ({ module }) => {
  return <article className="pad-lg module form-module" style={{ backgroundColor: `var(--bg-${module.color})` }}>
    <div className="grid grid--50">
      <div className="form-module-copy grid-block">
        <h3>{module.heading}</h3>
        <p>{module.copy}</p>
      </div>
      <div className="form grid-block">
        <div className="article" dangerouslySetInnerHTML={{ __html: module.embed }} />
      </div>
    </div>
  </article>
}

const ModuleBuilder = ({ module }) => {
  const block = module.children[0]

  switch (module.typeHandle) {
    case "simpleModule":
      return <article className="module resort-end" style={{ backgroundColor: `var(--bg-${module.color})` }}>
        <div className="max-width pad-top pad-bottom">
          <div className={block.image[0] ? 'grid grid--50' : 'center'}>
            <div className="module-content grid-block">
              <h3>{block.heading}</h3>
              <BodyText body={block.body} />
              {block.linkTo && block.linkTo.length ? <div className="buttons">{
                block.linkTo.map(link => <Button key={link.id} {...link} />)}
              </div> : null}
            </div>
            {block.image[0] ? <Frame>
              <Image image={block.image[0]} />
            </Frame> : null}
          </div>
        </div>
      </article>

    case "imageModule":
      return <ImageModule module={module} block={block} />

    case "gridModule":
      return <article className="pad-top pad-bottom module resort-end grid-module max-width">
        <SectionHeader heading={module.heading} />
        <ul className={`flex-list list-length--${module.children.length}`}>
          {module.children.map(item => {
            return <li key={item.id} className="pad">
              {item.image[0] ? <figure><img className="grid-module-img" src={item.image[0].url} alt={`icon of ${item.heading}`} /></figure> : null}
              <h3>{item.heading}</h3>
              <p className="article" dangerouslySetInnerHTML={{ __html: item.body }} />
              {item.linkTo && item.linkTo.length ?
                <ArrowLink
                  pageEntry={item.linkTo[0].page ? item.linkTo[0].page[0] : item.linkTo[0].linkUrl}
                  linkCta={item.linkTo[0].linkCta ? item.linkTo[0].linkCta : "Read More"}
                />
                : null}
            </li>
          })}
        </ul>
      </article>

    case "timeline":
      return <div className="pad-top pad-bottom resort-end" style={{ backgroundColor: "var(--blue)" }}>
        <SectionHeader heading={module.heading} />
        <Timeline blocks={module.children} />
      </div>

    case "storiesModule":
      if (block.typeHandle === "profileSelect") {
        return <section className="pad-top pad-bottom max-width">
          <SectionHeader heading={module.heading} />
          <ul className="grid grid--33">
            {block.profileSelection.map((article) => {
              return article.id && <li key={article.id}>
                <NewsModule article={article} color={"white"} />
              </li>
            })
            }
          </ul>
        </section>
      }
      return block.newsEntryType ?
        <RelatedContent color="white" heading={module.heading} type={block.newsEntryType} limit={6} />
        : <StoryModule heading={module.heading} block={block} />

    default:
      return null
  }
}

const StoryModule = ({ heading, block }) => {
  const staffids = useFetchStore(staffIds, "staff")
  let ids = staffids ? staffids.entries.map(per => parseInt(per.id)) : null

  return ids && block.storyType === "staffStory" ?
    <RelatedContent color="white" heading={heading} type={"story"} limit={6} relatedTo={ids} /> :
    <RelatedContent color="white" heading={heading} type={"story"} limit={6} />

}

const ImageModule = ({ module, block }) => {
  return module.imageAsBackground ?
    <article className="pad-lg module resort-end image-module">
      <div className="bg-image" style={{
        backgroundImage: `url('${block.image[0].url}')`
      }}></div>
      <div className={"center"}>
        <div className="module-content">
          <h3>{block.heading}</h3>
          <p className="article" dangerouslySetInnerHTML={{ __html: block.body }} />
          {block.linkTo ? <div className="buttons">{
            block.linkTo.map(link => <Button key={link.id} {...link} />)}
          </div> : null}
        </div>
      </div>
    </article>
    :
    <article className="module pad-top offset-image-module max-width">
      <div className="bg-image" style={{
        backgroundImage: `url('${block.image[0].url}')`
      }}></div>
      <div className="pad-lg offset">
        <div className={"module-content grid grid--50"}>
          <h3 className="grid-block">{block.heading}</h3>
          <div className="grid-block">
            <p className="article" dangerouslySetInnerHTML={{ __html: block.body }} />
            {block.linkTo ? <div className="buttons">{
              block.linkTo.map(link => <Button key={link.id} {...link} />)}
            </div> : null}
          </div>
        </div>
      </div>
    </article>
}
